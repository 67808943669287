import { api } from '@/utils/fetch';
import { queryOptions } from '@tanstack/react-query';

export interface Model {
  id: number;
  name: 'string';
  description: 'string';
  enabled_models: Array<{
    id: number;
    name: 'string';
    description: 'string';
  }>;
}

export interface ApiKey {
  id: number;
  name: string;
  key: string;
  enabled: boolean;
  created: string;
  modified: string;
}

export interface Provider {
  name: string;
  description: string;
}

export interface QueryHistory {
  id: number;
  conversation_id: string;
  model: {
    name: string;
    description: string;
    provider: Provider;
  };
  key: ApiKey;
  query: string;
  response: string;
}

export async function fetchModels(options?: DefaultFetchOptions) {
  return api<PaginatedResponse<Model>>('/api/v1/llm/models/', {
    method: 'GET',
    signal: options?.signal,
  });
}

export const fetchModelsOptions = queryOptions({
  queryKey: ['models'],
  queryFn: ({ signal }) => fetchModels({ signal }),
});

export async function fetchKeys(options?: DefaultFetchOptions) {
  return api<PaginatedResponse<ApiKey>>('/api/v1/llm/keys/', {
    method: 'GET',
    signal: options?.signal,
  });
}

export const fetchKeysOptions = queryOptions({
  queryKey: ['keys'],
  queryFn: ({ signal }) => fetchKeys({ signal }),
});

export async function fetchKey(id: number, options?: DefaultFetchOptions) {
  return api<PaginatedResponse<ApiKey>>(`/api/v1/llm/keys/${id}/`, {
    method: 'GET',
    signal: options?.signal,
  });
}

export function fetchKeyOptions(id: number) {
  return queryOptions({
    queryKey: ['keys', id],
    queryFn: ({ signal }) => fetchKey(id, { signal }),
  });
}

export async function fetchHistory(options?: DefaultFetchOptions) {
  return api<PaginatedResponse<QueryHistory>>('/api/v1/llm/history/', {
    signal: options?.signal,
  });
}

export function fetchKeyHistoryOptions() {
  return queryOptions({
    queryKey: ['history'],
    queryFn: ({ signal }) => fetchHistory({ signal }),
    staleTime: Number.POSITIVE_INFINITY,
  });
}

export async function createKey(options?: DefaultFetchOptions) {
  return api<ApiKey>('/api/v1/llm/keys/', {
    method: 'POST',
    signal: options?.signal,
  });
}

export async function updateKey(
  id: number,
  body: { name: string },
  options?: DefaultFetchOptions,
) {
  return api<ApiKey>(`/api/v1/llm/keys/${id}/`, {
    method: 'PATCH',
    body,
    signal: options?.signal,
  });
}

export async function deleteKey(id: number, options?: DefaultFetchOptions) {
  return api(`/api/v1/llm/keys/${id}/`, {
    method: 'DELETE',
    signal: options?.signal,
  });
}
