import { useAuth } from '@/auth';
import { ButtonGoogleSignIn } from '@/components/button-google-signin';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { fetchProfile } from '@/models/profile';
import { isServerError, isValidationError } from '@/utils/guards';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link, createFileRoute } from '@tanstack/react-router';
import { FetchError } from 'ofetch';
import TagManager from 'react-gtm-module';
import { useForm } from 'react-hook-form';
import { useHead } from 'unhead';
import { z } from 'zod';

export const Route = createFileRoute('/_auth/signin')({
  component: Login,
});

const schema = z.object({
  email: z.string().email(),
  password: z.string().min(8),
});

function Login() {
  const auth = useAuth();
  const { redirect } = Route.useSearch<{ redirect: string }>();
  useHead({ title: 'Sign In' });

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = form.handleSubmit(async (values) => {
    try {
      auth.setIsLoading(true);
      await auth.login({ provider: 'credentials', ...values });
      const profile = await fetchProfile();
      if (profile) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'login',
            info: {
              pricingPlan: profile.subscribed ? 'premium' : 'free',
              user_email: profile.email,
            },
          },
        });
      }
      window.location.href = redirect ?? '/chat';
    } catch (error) {
      auth.setIsLoading(false);
      if (
        error &&
        error instanceof FetchError &&
        isValidationError(error.data)
      ) {
        for (const err of error.data.errors) {
          if (err.attr === 'non_field_errors') {
            form.setError('root', { type: 'custom', message: err.detail });
          } else {
            form.setError(err.attr as keyof z.infer<typeof schema>, {
              type: 'custom',
              message: err.detail,
            });
          }
        }
      }

      if (error && error instanceof FetchError && isServerError(error.data)) {
        for (const err of error.data.errors) {
          form.setError('root', { type: 'custom', message: err.detail });
        }
      }
    }
  });

  return (
    <div
      className={cn(
        'relative w-full max-w-md rounded-xl border border-gray-200 bg-white shadow-sm transition-all duration-500',
        {
          'cursor-not-allowed before:absolute before:inset-0 before:rounded-xl before:bg-neutral-300 before:bg-white/10 before:backdrop-blur-sm':
            auth.isLoading,
        },
      )}
    >
      <div className="p-4 sm:p-7">
        <img
          className="mx-auto aspect-square w-32 rounded-full"
          src="/header-logo.webp"
          alt="Bytepath"
        />
        <div className="mt-4 text-center">
          <h1 className="block font-bold text-2xl text-gray-800">Sign in</h1>
          <p className="mt-2 text-gray-600 text-sm">
            Don't have an account yet?{' '}
            <Link
              className="font-medium text-accent-foreground decoration-2 hover:underline"
              to="/signup"
            >
              Sign up here
            </Link>
          </p>
        </div>

        <div className="mt-5">
          <ButtonGoogleSignIn />

          <div className="flex items-center py-3 text-gray-600 text-xs uppercase after:ms-6 before:me-6 after:flex-[1_1_0%] before:flex-[1_1_0%] after:border-gray-200 before:border-gray-200 after:border-t before:border-t">
            Or
          </div>

          <Form {...form}>
            <form onSubmit={onSubmit}>
              <div className="grid gap-y-4">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email address</FormLabel>
                      <FormControl>
                        <Input
                          type="email"
                          autoComplete="email"
                          inputMode="email"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="flex items-center justify-between">
                        <span>Password</span>
                        <a
                          className="font-medium text-accent-foreground text-sm decoration-2 hover:underline"
                          href="/password-reset"
                        >
                          Forgot password?
                        </a>
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="password"
                          autoComplete="current-password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {form.formState.errors.root ? (
                  <p
                    className="font-semibold text-destructive text-xs"
                    role="alert"
                  >
                    {form.formState.errors.root.message}
                  </p>
                ) : null}

                <Button disabled={auth.isLoading}>Sign In</Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}
