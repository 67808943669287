import { DropdownProfile } from '@/components/dropdown-profile';
import { useDialogs } from '@/dialogs';
import { fetchModelsOptions } from '@/models/llm';
import { fetchProfileOptions } from '@/models/profile';
import { navigationLinks } from '@/utils/constants';
import { useSuspenseQuery } from '@tanstack/react-query';
import {
  Link,
  Outlet,
  createFileRoute,
  redirect,
} from '@tanstack/react-router';
import { SparklesIcon } from 'lucide-react';
import { createElement } from 'react';

export const Route = createFileRoute('/_layout')({
  beforeLoad: ({ location, context }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: '/signin',
        search: {
          redirect: location.href,
        },
      });
    }
  },
  loader: async ({ context }) => {
    const profile =
      await context.queryClient.ensureQueryData(fetchProfileOptions);
    const models =
      await context.queryClient.ensureQueryData(fetchModelsOptions);

    return {
      profile,
      models,
    };
  },
  component: Layout,
});

function Layout() {
  const { showDialog } = useDialogs();
  const { data: profile } = useSuspenseQuery(fetchProfileOptions);

  const fullName = `${profile?.first_name} ${profile?.last_name}`;
  const initials = `${profile?.first_name?.[0]}${profile?.last_name?.[0]}`;
  const email = profile?.email;
  const isPremium = profile?.subscribed;
  const remainingQueries = profile?.remaining_queries || 0;

  return (
    <div className="md:grid md:grid-cols-[auto_1fr]">
      <div className="flex-shrink-0 border bg-card">
        <aside className="hidden w-[260px] md:block">
          <nav className="flex h-dvh w-full flex-col px-3 py-3.5">
            <div className="flex-1 overflow-y-auto">
              <header className="sticky top-0">
                <Link className="flex items-center gap-x-2" to="/">
                  <img
                    className="aspect-square w-10 rounded-full"
                    src="/header-logo.webp"
                    alt="BytePath logo"
                  />
                  <span className="font-bold text-xl tracking-tight">
                    BytePath
                  </span>
                </Link>
              </header>

              <ul className="space-y-1.5 py-4 font-semibold text-foreground">
                {navigationLinks.map(({ name, icon, href }) => (
                  <li key={href}>
                    <Link
                      className="group flex items-center gap-x-3 rounded-lg px-3 py-2 text-sm hover:bg-accent"
                      activeProps={{
                        className: 'bg-accent text-primary font-bold',
                      }}
                      to={href}
                    >
                      {createElement(icon, {
                        className: 'group-data-[status=active]:text-primary',
                      })}
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex flex-col pt-2 empty:hidden">
              {!profile?.subscribed ? (
                <button
                  className="flex w-full items-center gap-2 rounded-lg p-2 text-sm transition-all duration-300 hover:bg-background"
                  type="button"
                  onClick={() => showDialog('pricing')}
                >
                  <span className="flex h-10 w-10 items-center justify-center rounded-full border border-foreground p-1 text-foreground">
                    <SparklesIcon className="h-8 w-8" />
                  </span>
                  <div className="-top-px -space-y-px relative grow overflow-hidden text-ellipsis whitespace-nowrap text-left text-foreground">
                    <div className="font-semibold">Upgrade plan</div>
                  </div>
                </button>
              ) : null}

              <DropdownProfile
                fullName={fullName}
                initials={initials}
                email={email}
                isPremium={isPremium}
                remainingQueries={remainingQueries}
              />
            </div>
          </nav>
        </aside>
      </div>

      <Outlet />
    </div>
  );
}
