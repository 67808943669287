import { useAuth } from '@/auth';
import { Button, buttonVariants } from '@/components/ui/button';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from '@/components/ui/navigation-menu';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { navigationLinks } from '@/utils/constants';
import { MenuIcon } from 'lucide-react';
import { createElement } from 'react';

export function MobileNavigation() {
  const { logout } = useAuth();

  return (
    <Sheet>
      <SheetTrigger
        className={buttonVariants({
          className: 'float-end ml-auto md:hidden',
          variant: 'outline',
          size: 'icon',
        })}
      >
        <MenuIcon />
      </SheetTrigger>
      <SheetContent className="grid grid-rows-[1fr_auto] items-start">
        <NavigationMenu orientation="vertical">
          <NavigationMenuList className="flex-col gap-5">
            {navigationLinks.map((link) => (
              <NavigationMenuItem
                className="flex w-full items-center gap-x-2 justify-self-start"
                key={link.href}
              >
                {createElement(link.icon, {
                  className: 'group-data-[status=active]:text-primary',
                })}
                <NavigationMenuLink href={link.href}>
                  {link.name}
                </NavigationMenuLink>
              </NavigationMenuItem>
            ))}
          </NavigationMenuList>
        </NavigationMenu>

        <Button
          variant="ghost"
          type="button"
          onClick={async () => {
            await logout();
            window.location.href = '/';
          }}
        >
          Sign Out
        </Button>
      </SheetContent>
    </Sheet>
  );
}
