import { CircleUserIcon, CoinsIcon, TerminalSquareIcon } from 'lucide-react';

export const ACCESS_COOKIE = 'jwt-access-token';
export const REFRESH_COOKIE = 'jwt-refresh-token';
export const ACCESS_TOKEN = 'access-token';
export const REFRESH_TOKEN = 'refresh-token';

export const LLM_MODEL = 'llm:selected-model';
export const LLM_KEY = 'llm:selected-key';
export const EXPERIMENTS = 'llm:experiments';

export const LINKS = [
  // { text: 'Dashboard', href: '/dashboard' },
  { text: 'Chat', href: '/chat' },
] as const;

export const navigationLinks = [
  { name: 'Playground', icon: TerminalSquareIcon, href: '/chat' },
  { name: 'Billing', icon: CoinsIcon, href: '/billing' },
  { name: 'Profile', icon: CircleUserIcon, href: '/profile' },
] as const;

// USD formatter
export const formatUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});
